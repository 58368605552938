<template>
  <Breadcrumb v-if="detailTitle" :detailTitle="detailTitle.toUpperCase()" />
  <Breadcrumb v-else detailTitle="&nbsp;" />
  <div class="alert alert-info">
    <p
      class="text-center"
      v-html="
        this.$t(
          'Users.CacheDescription',
          {},
          { locale: this.$store.state.activeLang }
        ).replace('USER_NAME', detailTitle)
      "
    ></p>
    <div class="w-100 text-center">
      <button
        type="button"
        :disabled="cacheReset"
        class="btn btn-warning"
        @click="cacheReload"
      >
        <i class="bi bi-arrow-counterclockwise"></i>
        {{
          $t("Users.CacheReload", {}, { locale: this.$store.state.activeLang })
        }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="permission-tab"
            data-bs-toggle="tab"
            data-bs-target="#permissions"
            type="button"
            role="tab"
            aria-selected="true"
          >
            {{
              $t(
                "Users.Permissions",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="action-tab"
            data-bs-toggle="tab"
            data-bs-target="#actions"
            type="button"
            role="tab"
            aria-controls="action"
            aria-selected="false"
          >
            {{
              $t("Users.Actions", {}, { locale: this.$store.state.activeLang })
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="menu-tab"
            data-bs-toggle="tab"
            data-bs-target="#menus"
            type="button"
            role="tab"
            aria-controls="menu"
            aria-selected="false"
          >
            {{ $t("Users.Menu", {}, { locale: this.$store.state.activeLang }) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="usergroup-tab"
            data-bs-toggle="tab"
            data-bs-target="#usergroups"
            type="button"
            role="tab"
            aria-controls="usergroup"
            aria-selected="false"
          >
            {{
              $t(
                "Users.UserGroups",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="permissiongroups-tab"
            data-bs-toggle="tab"
            data-bs-target="#permissiongroups"
            type="button"
            role="tab"
            aria-controls="permissiongroup"
            aria-selected="false"
          >
            {{
              $t(
                "Users.PermissionGroups",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="viewfilters-tab"
            data-bs-toggle="tab"
            data-bs-target="#viewfilters"
            type="button"
            role="tab"
            aria-controls="viewfilter"
            aria-selected="false"
          >
            {{
              $t(
                "Users.ViewFilters",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pagelayouts-tab"
            data-bs-toggle="tab"
            data-bs-target="#pagelayouts"
            type="button"
            role="tab"
            aria-controls="pagelayout"
            aria-selected="false"
          >
            {{
              $t(
                "Users.PageDesigners",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="custompages-tab"
            data-bs-toggle="tab"
            data-bs-target="#custompages"
            type="button"
            role="tab"
            aria-controls="custompage"
            aria-selected="false"
          >
            {{
              $t(
                "Users.CustomPages",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="dashboards-tab"
            data-bs-toggle="tab"
            data-bs-target="#dashboards"
            type="button"
            role="tab"
            aria-controls="dashboard"
            aria-selected="false"
          >
            {{
              $t(
                "Users.DashboardReports",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="xtraReports-tab"
            data-bs-toggle="tab"
            data-bs-target="#xtraReports"
            type="button"
            role="tab"
            aria-controls="xtraReport"
            aria-selected="false"
          >
            {{
              $t(
                "Users.XtraReports",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="tab-content mt-1">
        <div
          class="tab-pane fade show active"
          id="permissions"
          role="tabpanel"
          aria-labelledby="permission-tab"
        >
          <div
            v-if="userData.permissionsOfCustomObjects == null"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th>
                    {{
                      $t(
                        "Users.CustomObject",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.Create",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.Read",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.DetailRead",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.FileRead",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.DataExport",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.Update",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.Delete",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.History",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in userData.permissionsOfCustomObjects"
                  :key="item.key"
                  :data-id="item.key"
                >
                  <td class="pg-title align-middle">
                    {{ item.customObjectName }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input create"
                        type="checkbox"
                        :checked="item.isCreateAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input read"
                        type="checkbox"
                        :checked="item.isReadAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input detail-read"
                        type="checkbox"
                        :checked="item.isReadDetailAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input file-read"
                        type="checkbox"
                        :checked="item.isReadDocumentsAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input data-export"
                        type="checkbox"
                        :checked="item.isDataExportsAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input update"
                        type="checkbox"
                        :checked="item.isUpdateAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input delete"
                        type="checkbox"
                        :checked="item.isDeleteAllowed"
                        disabled
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input history"
                        type="checkbox"
                        :checked="item.isHistoryAllowed"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="actions"
          role="tabpanel"
          aria-labelledby="action-tab"
        >
          <div
            v-if="userData.permissionsOfActions == null"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start">
                    {{
                      $t(
                        "Users.Action",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "Users.Process",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.permissionsOfActions"
                  :key="index"
                >
                  <td class="pg-title align-middle">
                    {{ item.actionName }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="true"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="menus"
          role="tabpanel"
          aria-labelledby="menu-tab"
        >
          <div v-if="userData.activeMenu == null" class="alert alert-warning">
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start">
                    {{
                      $t(
                        "Users.Menu",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th style="width: 220px" class="text-center">
                    {{
                      $t(
                        "Users.ChildRecordCount",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th style="width: 220px" class="text-center">
                    {{
                      $t(
                        "Users.Detail",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pg-title align-middle">
                    {{ userData.activeMenu.name }}
                  </td>
                  <td class="text-center pg-title align-middle">
                    {{ userData.activeMenuItems.length }}
                  </td>
                  <td class="text-center align-middle">
                    <a
                      type="button"
                      @click="menuDetailRedirect(userData.activeMenu.publicId)"
                      class="e-lib e-btn e-control btn-primary detail btn e-icon-btn"
                    >
                      <span
                        class="e-btn-icon bi bi-arrow-up-right-square"
                      ></span>
                      {{
                        $t(
                          "Users.Detail",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="usergroups"
          role="tabpanel"
          aria-labelledby="usergroup-tab"
        >
          <div v-if="userData.userGroups == null" class="alert alert-warning">
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start">
                    {{
                      $t(
                        "Users.UserGroupName",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "Users.Process",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in userData.userGroups" :key="index">
                  <td class="pg-title align-middle">
                    {{ item.name }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="true"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="permissiongroups"
          role="tabpanel"
          aria-labelledby="permissiongroups-tab"
        >
          <div
            v-if="userData.permissionGroups == null"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start">
                    {{
                      $t(
                        "Users.PermissionGroupName",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "Users.Process",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in userData.permissionGroups"
                  :key="index"
                >
                  <td class="pg-title align-middle">
                    {{ item.name }}
                  </td>
                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="true"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="viewfilters"
          role="tabpanel"
          aria-labelledby="viewfilters-tab"
        >
          <div
            v-if="viewFilterGroupByCustomObject.length == 0"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div v-else>
            <div class="card bg-light mb-2">
              <div class="card-body p-2">
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="mb-2 mb-md-0 col-3">
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeCustomObjectForViewFilterTab"
                      requestUrl="/Lcdp-SummaryCustomObjects"
                      :isGatewayRequest="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-pg">
                <thead>
                  <tr>
                    <th class="text-start">
                      {{
                        $t(
                          "Users.CustomObject",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t(
                          "Users.ViewFilter",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody id="viewfilter-tbody">
                  <template v-for="item in this.viewFilterGroupByCustomObject">
                    <tr
                      v-for="(subitem, iSub) in item.viewFilterCacheInfoList"
                      :key="subitem.publicid"
                      :data-coid="item.customObjectPublicId"
                    >
                      <td
                        class="pg-title align-middle text-break"
                        v-if="iSub === 0"
                        :rowspan="item.viewFilterCacheInfoList.length"
                      >
                        {{ subitem.customObjectName }}
                      </td>
                      <td class="pg-title align-middle text-break">
                        {{ subitem.name }}
                        <span
                          class="badge badge-warning"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            this.$t(
                              'ViewFilters.IsDefaultFilter',
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          "
                          v-if="iSub === 0"
                        >
                          <i class="bi bi-star"></i>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pagelayouts"
          role="tabpanel"
          aria-labelledby="pagelayouts-tab"
        >
          <div
            v-if="pageLayoutGroupByCustomObject.length == 0"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div v-else>
            <div class="card bg-light mb-2">
              <div class="card-body p-2">
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="mb-2 mb-md-0 col-3">
                    <FormSelect
                      type="SelectWithRemoteDataAndInternalSearch"
                      @onChange="onChangeCustomObject"
                      requestUrl="/Lcdp-SummaryCustomObjects"
                      :isGatewayRequest="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-pg">
                <thead>
                  <tr>
                    <th class="text-start">
                      {{
                        $t(
                          "Users.CustomObject",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t(
                          "Users.PageDesigner",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody id="pagelayout-tbody">
                  <template v-for="item in this.pageLayoutGroupByCustomObject">
                    <tr
                      v-for="(subitem, iSub) in item.pageLayouts"
                      :key="subitem.publicid"
                      :data-coid="item.customObjectPublicId"
                    >
                      <td
                        class="pg-title align-middle text-break"
                        v-if="iSub === 0"
                        :rowspan="item.pageLayouts.length"
                      >
                        {{ subitem.customObjectName }}
                      </td>
                      <td class="pg-title align-middle text-break">
                        {{ subitem.name }}
                        <span
                          class="badge badge-warning"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            this.$t(
                              'PageLayouts.Main',
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          "
                          v-if="iSub === 0"
                        >
                          <i class="bi bi-star"></i>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="custompages"
          role="tabpanel"
          aria-labelledby="custompages-tab"
        >
          <div
            v-if="customPagesPermission.length == 0"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",

                {},

                { locale: this.$store.state.activeLang }
              )
            }}
          </div>

          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th class="text-start">
                    {{
                      $t(
                        "Users.CustomPageName",

                        {},

                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>

                  <th width="150">
                    {{
                      $t(
                        "Users.Process",

                        {},

                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in customPagesPermission" :key="index">
                  <td class="pg-title align-middle">
                    {{ item.name }}
                  </td>

                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="true"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="dashboards"
          role="tabpanel"
          aria-labelledby="dashboards-tab"
        >
          <div
            v-if="dashboardReportPermission.length == 0"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",

                {},

                { locale: this.$store.state.activeLang }
              )
            }}
          </div>

          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th width="50">
                    {{
                      $t(
                        "Users.DashboardReportOrder",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.DashboardReportName",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.DashboardReportFormulaName",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "Users.Process",

                        {},

                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in dashboardReportPermission"
                  :key="index"
                >
                  <td class="pg-title align-middle text-break text-center">
                    {{ item.order }}
                  </td>
                  <td class="pg-title align-middle text-break text-center">
                    {{ item.reportName }}
                  </td>
                  <td class="pg-title align-middle text-break text-center">
                    {{ item.reportFormulaName }}
                  </td>

                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="true"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="xtraReports"
          role="tabpanel"
          aria-labelledby="xtraReports-tab"
        >
          <div
            v-if="xtraReportPermission.length == 0"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",

                {},

                { locale: this.$store.state.activeLang }
              )
            }}
          </div>

          <div class="table-responsive" v-else>
            <table class="table table-bordered table-hover table-pg">
              <thead>
                <tr>
                  <th width="50">
                    {{
                      $t(
                        "Users.XtraReportOrder",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.XtraReportName",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th>
                    {{
                      $t(
                        "Users.XtraReportFormulaName",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th width="150">
                    {{
                      $t(
                        "Users.Process",

                        {},

                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in xtraReportPermission" :key="index">
                  <td class="pg-title align-middle text-break text-center">
                    {{ item.order }}
                  </td>
                  <td class="pg-title align-middle text-break text-center">
                    {{ item.reportName }}
                  </td>
                  <td class="pg-title align-middle text-break text-center">
                    {{ item.reportFormulaName }}
                  </td>

                  <td>
                    <div
                      class="form-check form-switch form-switch-md d-flex justify-content-center align-items-center"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="true"
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "Cache",
  data() {
    return {
      userData: {},
      pageLayoutGroupByCustomObject: [],
      viewFilterGroupByCustomObject: [],
      detailTitle: "",
      cacheReset: false,
      customObjectSelectReset: false,
      customPagesPermission: [],
      dashboardReportPermission: [],
      xtraReportPermission: [],
    };
  },
  methods: {
    cacheReload() {
      this.cacheReset = true;
      this.$prodGatewayAxios
        .post(String.format("/Lcdp-CacheSetUserCacheValueClient"), {
          publicId: this.$route.params.userId,
        })
        .then((response) => {
          if (response.data.isOk) {
            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
            location.reload();
          } else {
            createToast(
              this.$t(
                "Messages.ErrorMesage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeCustomObject(selected) {
      $("#pagelayout-tbody tr").show();
      if (!Array.isArray(selected)) {
        $("#pagelayout-tbody tr").each(function (i, v) {
          if ($(v).data("coid") !== selected.key) {
            $(v).hide();
          }
        });
      }
    },
    onChangeCustomObjectForViewFilterTab(selected) {
      $("#viewfilter-tbody tr").show();
      if (!Array.isArray(selected)) {
        $("#viewfilter-tbody tr").each(function (i, v) {
          if ($(v).data("coid") !== selected.key) {
            $(v).hide();
          }
        });
      }
    },
    menuDetailRedirect(id) {
      this.$router.push({ name: "MenuListItem", params: { menuId: id } });
    },
    getUserCache() {
      var self = this;
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-GetUser?id={0}", this.$route.params.userId))
        .then((response) => {
          if (response.data.isOk) {
            self.userData = response.data.item;
            self.detailTitle = response.data.item.name;

            response.data.item.permissionsOfPageLayouts.forEach((element) => {
              self.pageLayoutGroupByCustomObject.push(element);
            });

            response.data.item.permissionsOfViewFilters.forEach((element) => {
              self.viewFilterGroupByCustomObject.push(element);
            });

            self.pageLayoutGroupByCustomObject.forEach((element) => {
              if (element.pageLayouts.filter((x) => x.isMain).length > 0) {
                var orderedDisplayOrder = element.pageLayouts.sort(function (
                  a,
                  b
                ) {
                  return Number(a.displayOrder) - Number(b.displayOrder);
                });

                element.pageLayouts = orderedDisplayOrder.sort(function (a, b) {
                  return b.isMain - a.isMain;
                });
              } else {
                element.pageLayouts.sort(function (a, b) {
                  return Number(a.displayOrder) - Number(b.displayOrder);
                });
              }
            });
            response.data.item.permissionsOfCustomPages.forEach((element) => {
              self.customPagesPermission.push(element);
            });
            self.viewFilterGroupByCustomObject.forEach((element) => {
              element.viewFilterCacheInfoList.sort(function (a, b) {
                return b.isDefaultFilter - a.isDefaultFilter;
              });
            });
            response.data.item.permissionsOfReports.forEach((element) => {
              if (element.reportingTypeId == 1) {
                self.dashboardReportPermission.push(element);
              } else if (element.reportingTypeId == 2) {
                self.xtraReportPermission.push(element);
              }
            });
            self.customPagesPermission.forEach((element) => {
              if (element.customPages.filter((x) => x.isMain).length > 0) {
                var orderedDisplayOrder = element.customPages.sort(function (
                  a,

                  b
                ) {
                  return Number(a.displayOrder) - Number(b.displayOrder);
                });

                element.customPages = orderedDisplayOrder.sort(function (a, b) {
                  return b.isMain - a.isMain;
                });
              } else {
                element.customPages.sort(function (a, b) {
                  return Number(a.displayOrder) - Number(b.displayOrder);
                });
              }
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getUserCache();
  },
};
</script>
